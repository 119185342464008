<template>
  <div>
    <el-form label-position="right">
      <TitleAndDescription :field="field" />
      <el-row type="flex" :gutter="50">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-checkbox
            v-model="field.integration_settings.selectFromTemplate"
            @change="handleSelectFromTemplate"
            >Select from Template</el-checkbox
          >
        </el-col>
      </el-row>
      <br />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Select Application *">
            <el-select
              v-model="field.integration_settings.selectedApplication"
              placeholder="Select an application"
              filterable
              size="large"
              @change="handleApplicationChange"
              style="width: 100%"
            >
              <el-option
                v-for="application in predefinedIntegrations"
                :key="application._id"
                :label="application.label"
                :value="application._id"
              >
                <img
                  :src="application.logo"
                  alt="logo"
                  style="width: 20px; height: 20px; margin-right: 1em"
                />
                {{ application.label }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
            label="Integrations *"
            v-if="!field.integration_settings.selectFromTemplate"
          >
            <el-select
              v-model="field.integration_settings.selectedIntegration"
              no-data-text="No Integrations available"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="integration in filteredIntegrations"
                :value="integration.connection_id"
                :key="integration._id"
                :label="integration.name"
              >
                <span style="float: left">{{ integration.name }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Template Fields" v-else>
            <el-select
              v-model="field.integration_settings.templateDependencies"
              placeholder="Select a template"
              style="width: 100%"
              multiple
              default-first-option
            >
              <el-option
                v-for="template in this.fieldsData"
                :key="template._id"
                :label="template.label"
                :value="template.key"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        type="flex"
        :gutter="50"
        v-if="
          selectedIntegrationDependencies.length > 0 ||
          selectedIntegrationEvents.length > 0
        "
      >
        <el-col :span="12" v-if="selectedIntegrationDependencies.length > 0">
          <el-form-item label="Select Dependency">
            <el-select
              v-model="selectedDependency"
              placeholder="Select a dependency"
              clearable
              style="width: 100%"
            >
              <el-option
                v-for="dependency in selectedIntegrationDependencies"
                :key="dependency.key"
                :label="dependency.label"
                :value="dependency.key"
              >
                {{ dependency.label }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="selectedIntegrationEvents.length > 0">
          <el-form-item label="Select Event *">
            <el-select
              v-model="field.integration_settings.selectedEvent"
              placeholder="Select an event"
              @change="handleEventChange"
              style="width: 100%"
            >
              <el-option
                v-for="event in selectedIntegrationEvents"
                :key="event._id"
                :label="event.eventName"
                :value="event._id"
              >
                {{ event.eventName }} - {{ event.eventDescription }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <div
        v-if="
          requiredFieldsForEvent.length > 0 &&
          !field.integration_settings.selectFromTemplate
        "
      >
        <el-row type="flex" :gutter="50">
          <el-col
            v-for="requiredField in requiredFieldsForEvent"
            :key="requiredField.key"
            :span="12"
          >
            <el-form-item :label="requiredField.label + ' *'">
              <el-select
                v-if="requiredField.type === 'select'"
                v-model="
                  field.integration_settings.requiredFields[requiredField.key]
                "
                :placeholder="'Select ' + requiredField.label"
                @change="handleRequiredFieldChange(requiredField)"
                style="width: 100%"
              >
                <el-option
                  v-for="option in requiredField.values"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </el-option>
              </el-select>
              <el-input
                v-else-if="requiredField.type === 'input'"
                v-model="
                  field.integration_settings.requiredFields[requiredField.key]
                "
                :placeholder="'Enter ' + requiredField.label"
                style="width: 100%"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row type="flex" :gutter="50" v-if="availableColumns.length > 0">
        <el-col :span="12">
          <el-form-item label="Select Label *">
            <el-select
              v-model="field.integration_settings.selectedColumnLabel"
              placeholder="Select Label"
              style="width: 100%"
            >
              <el-option
                v-for="column in availableColumns"
                :key="column.key"
                :label="column.label"
                :value="column.key"
              >
                {{ column.label }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Select Identifier">
            <el-select
              v-model="field.integration_settings.selectedColumnKey"
              placeholder="Select Key"
              style="width: 100%"
            >
              <el-option
                v-for="column in availableColumns"
                :key="column.key"
                :label="column.key"
                :value="column.key"
              >
                {{ column.key }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { postAPICall } from "../../../helpers/httpHelper";
export default {
  props: ["field", "fieldsData", "index"],
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
  },
  data() {
    return {
      predefinedIntegrations: [],
      selectedIntegrationEvents: [],
      selectedIntegrationDependencies: [],
      selectedDependency: null,
      selectedEvent: null,
      selectedApplication: null,
      requiredFieldsForEvent: [],
      availableColumns: [],
      availableKeys: [],
      selectedLabel: null,
      selectedColumnLabel: null,
      selectedColumnKey: null,
      selectedAppIntegration: null,
      applicationKey: "",
      formData: {},
      requiredFields: {},
      selectFromTemplate: false,
      templateDependencies: [],
    };
  },
  async mounted() {
    this.loading = true;
    await this.fetchIntegrations();
    await this.fetchAllPredefinedIntegrations();
    await this.fetchRequiredFieldData();
    if (this.field.integration_settings.selectedApplication) {
      this.handleApplicationChange(
        this.field.integration_settings.selectedApplication
      );
      this.handleEventChange(this.field.integration_settings.selectedEvent);
      this.handleRequiredFieldChange();
    }
    this.loading = false;
  },
  computed: {
    filteredIntegrations() {
      return this.integrations.filter(
        (integration) =>
          integration.application === this.applicationKey &&
          integration.status === "ACTIVE"
      );
    },
  },
  methods: {
    async fetchIntegrations() {
      let params = {
        limit: 10,
        page: 1,
      };
      this.loading = true;
      const response = await postAPICall("GET", `integrations`, params);
      this.integrations = response?.data || [];
      for (let integration of this.integrations) {
        if (integration.connection_id) {
          try {
            const response = await postAPICall(
              "GET",
              `integrations/service/get-tokens-by-connection/${integration.connection_id}`
            );
            integration.status = response?.data?.status || "Unknown";
          } catch (error) {
            console.error(
              `Failed to get status for integration ${integration.connection_id}`,
              error
            );
            integration.status = "Error";
          }
        } else {
          integration.status = "N/A";
        }
      }
      this.loading = false;
    },

    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },

    handleApplicationChange(applicationId) {
      const selectedAppIntegration = this.predefinedIntegrations.find(
        (integration) => integration._id === applicationId
      );
      if (selectedAppIntegration) {
        this.selectedIntegration = selectedAppIntegration;
        this.selectedIntegrationEvents = selectedAppIntegration.events || [];
        this.selectedIntegrationDependencies =
          selectedAppIntegration.dependencies || [];
        this.applicationKey = selectedAppIntegration.key || "";
      } else {
        this.selectedIntegrationEvents = [];
        this.selectedIntegrationDependencies = [];
        this.applicationKey = "";
      }
      this.selectedDependency = null;
      this.selectedEvent = null;
    },
    async handleEventChange(eventId) {
      const selectedEvent = this.selectedIntegrationEvents.find(
        (event) => event._id === eventId
      );
      if (
        selectedEvent &&
        selectedEvent.settings &&
        selectedEvent.settings.availableColumns
      ) {
        this.availableColumns = selectedEvent.settings.availableColumns;
      }
      if (
        selectedEvent &&
        selectedEvent.settings &&
        selectedEvent.settings.requiredFields
      ) {
        this.requiredFieldsForEvent = selectedEvent.settings.requiredFields;
        this.requiredFieldsForEvent.forEach((requiredField) => {
          if (
            !this.field.integration_settings.requiredFields[requiredField.key]
          ) {
            this.$set(
              this.field.integration_settings.requiredFields,
              requiredField.key,
              ""
            );
          }
        });
        for (let requiredField of this.requiredFieldsForEvent) {
          if (requiredField.dependencyField.length === 0) {
            const fieldsData = await this.fetchRequiredFieldData(
              requiredField.key
            );
            if (Array.isArray(fieldsData) && fieldsData.length > 0) {
              requiredField.values = fieldsData;
            }
          }
        }
      } else {
        this.requiredFieldsForEvent = [];
      }
    },

    async fetchRequiredFieldData(fieldKey) {
      try {
        const provider = this.selectedIntegration.key;
        const requestBody = {
          provider,
          field: fieldKey,
          formData: this.field.integration_settings.requiredFields,
        };
        const response = await postAPICall(
          "POST",
          `integrations/service/get-requiredFields-data/${this.field.integration_settings.selectedIntegration}`,
          requestBody
        );
        const data = response?.data || {};
        return data;
      } catch (error) {
        console.error(`Error fetching data for ${fieldKey}:`, error);
      }
    },
    async handleRequiredFieldChange(changedField) {
      const matchingDependencyFields = this.requiredFieldsForEvent.filter(
        (field) =>
          field.dependencyField &&
          field.dependencyField.includes(changedField.key)
      );
      const dependencyFieldCount = matchingDependencyFields.length;
      if (dependencyFieldCount > 1) {
        const hasAdditionalDependencies = matchingDependencyFields.some(
          (field) =>
            field.dependencyField.some(
              (dep) =>
                dep !== changedField.key &&
                !!this.field.integration_settings.requiredFields[dep]
            )
        );
        if (hasAdditionalDependencies) {
          let values = await this.fetchRequiredFieldData(
            matchingDependencyFields[1].key,
            this.field.integration_settings.requiredFields
          );
          this.$set(matchingDependencyFields[0], "values", values);
        }
      } else if (dependencyFieldCount === 1) {
        let values = await this.fetchRequiredFieldData(
          matchingDependencyFields[0].key,
          this.field.integration_settings.requiredFields
        );
        this.$set(matchingDependencyFields[0], "values", values);
      } 
    },
    handleSelectFromTemplate() {
      if (!this.field.integration_settings.selectFromTemplate) {
        if (this.field.integration_settings.templateDependencies) {
          this.field.integration_settings.templateDependencies = [];
        }
      } else {
        this.field.integration_settings.selectedIntegration = "";
        this.field.integration_settings.requiredFields = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-dialog {
  width: 100%;
}

.dialog-content {
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: thin;
}

.dialog-content::-webkit-scrollbar {
  width: 8px;
}

.dialog-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dialog-content::-webkit-scrollbar-thumb {
  background: #888;
}

.dialog-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.event-type-options {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
</style>
